import React from "react";
import { Checkbox } from "@contentful/f36-components";
import { Multiselect } from "@contentful/f36-multiselect";
import { useTranslationContext } from "../../context";
import { ACTION_TYPE, TranslationRequest } from "../../context/types";
import { LabeledLocale } from "../../lib";

interface ToLocaleSelectProps {
    translation: TranslationRequest;
    translationLocales: LabeledLocale[];
}

export const ToLocaleSelect: React.FC<ToLocaleSelectProps> = ({
    translation,
    translationLocales,
}) => {
    const { dispatch } = useTranslationContext();

    const availableLocales = translationLocales.filter(
        locale => translation.from !== locale.locale
    );

    const setTranslationTo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        dispatch({
            type: ACTION_TYPE.SET_TRANSLATION_TO,
            payload: {
                fieldId: translation.fieldId,
                toLocale: value,
            },
        });
    };

    const handleClearSelection = () => {
        dispatch({
            type: ACTION_TYPE.SET_ALL_TRANSLATION_TO,
            payload: {
                fieldId: translation.fieldId,
                toLocales: availableLocales.map(locale => locale.locale),
                toggle: "remove",
            },
        });
    };

    const setAllTranslationTo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        dispatch({
            type: ACTION_TYPE.SET_ALL_TRANSLATION_TO,
            payload: {
                fieldId: translation.fieldId,
                toLocales: availableLocales.map(locale => locale.locale),
                toggle: checked ? "add" : "remove",
            },
        });
    };

    const areAllSelected = availableLocales.every(locale => translation.to.includes(locale.locale));

    return translationLocales.length > 3 ? (
        <>
            <Multiselect
                currentSelection={translation.to.filter(locale => translation.from !== locale)}
                popoverProps={{ isFullWidth: true }}
                onClearSelection={handleClearSelection}>
                <Multiselect.SelectAll
                    onSelectItem={setAllTranslationTo}
                    isChecked={areAllSelected}
                />
                {availableLocales.map((locale, key) => (
                    <Multiselect.Option
                        key={key}
                        itemId={`${translation.fieldId}-${locale.locale}`}
                        value={locale.locale}
                        label={locale.label as string}
                        onSelectItem={setTranslationTo}
                        isChecked={translation.to.includes(locale.locale)}
                    />
                ))}
            </Multiselect>
        </>
    ) : (
        <Checkbox.Group value={translation.to as string[]} onChange={setTranslationTo}>
            {availableLocales.map((locale, key) => (
                <Checkbox key={key} value={locale.locale}>
                    {locale.label}
                </Checkbox>
            ))}
        </Checkbox.Group>
    );
};
