import React, { useState } from "react";
import { TextLink } from "@contentful/f36-components";
import { ExternalLinkIcon } from "@contentful/f36-icons";
import app from "../../package.json";

const hasUpdate = (currentVersion: string, viewedVersion?: string | null) => {
    if (!viewedVersion) {
        return true;
    }

    const [majorVersionCurrent, minorVersionCurrent] = currentVersion.split(".");
    const [majorVersionViewed, minorVersionViewed] = viewedVersion.split(".");

    return majorVersionCurrent !== majorVersionViewed || minorVersionCurrent !== minorVersionViewed;
};

export interface UpdateIndicatorProps {
    shouldHideAfterView?: boolean;
}

export const UpdateIndicator: React.FC<UpdateIndicatorProps> = ({ shouldHideAfterView }) => {
    const { version } = app;
    const viewedVersion = localStorage.getItem("hasViewedPatchNotes");

    const [showUpdateIndicator, setShowUpdateIndicator] = useState<boolean>(
        hasUpdate(version, viewedVersion)
    );

    if (shouldHideAfterView && !showUpdateIndicator) {
        return null;
    }

    const onView = () => {
        localStorage.setItem("hasViewedPatchNotes", version);
        setShowUpdateIndicator(false);
    };

    return (
        <TextLink
            icon={<ExternalLinkIcon />}
            alignIcon="end"
            href="https://www.contentful.com/marketplace/linguiny#:~:text=Version History"
            target="_blank"
            rel="noopener noreferrer"
            onClick={onView}>
            {`See what changed with Linguiny ${version}.`}
        </TextLink>
    );
};
