import { Locale } from "../lib";
import {
    preferredHigherSpecificityLocales,
    preferredSameSpecificityLocales,
} from "../lib/constants";

const findLowerSpecificityLocale = (locale: Locale, deeplLocales: Locale[]) => {
    let lowerSpecificityLocale = locale;
    let lastDashIndex = lowerSpecificityLocale.lastIndexOf("-");

    while (lastDashIndex !== -1) {
        lowerSpecificityLocale = lowerSpecificityLocale.substring(0, lastDashIndex);
        if (deeplLocales.includes(lowerSpecificityLocale)) {
            return lowerSpecificityLocale;
        }
        lastDashIndex = lowerSpecificityLocale.lastIndexOf("-");
    }
    return null;
};

const findHigherSpecificityLocale = (locale: Locale, deeplLocales: Locale[]) => {
    if (
        locale in preferredHigherSpecificityLocales &&
        deeplLocales.includes(preferredHigherSpecificityLocales[locale])
    ) {
        return preferredHigherSpecificityLocales[locale];
    }

    const higherSpecificityLocales = deeplLocales.filter(deeplLocale =>
        deeplLocale.includes(locale)
    );
    if (higherSpecificityLocales.length === 0) {
        return null;
    }
    if (higherSpecificityLocales.length === 1) {
        return higherSpecificityLocales[0];
    }

    return higherSpecificityLocales.reduce((minSpecificityLocale, currentLocale) => {
        const minDashCount = minSpecificityLocale.split("-").length - 1;
        const currentDashCount = currentLocale.split("-").length - 1;

        return currentDashCount < minDashCount ? currentLocale : minSpecificityLocale;
    });
};

const findSameSpecificityLocale = (locale: Locale, deeplLocales: Locale[]) => {
    if (
        locale in preferredSameSpecificityLocales &&
        deeplLocales.includes(preferredSameSpecificityLocales[locale])
    ) {
        return preferredSameSpecificityLocales[locale];
    }

    const lastDashIndex = locale.lastIndexOf("-");
    return lastDashIndex !== -1
        ? findHigherSpecificityLocale(locale.substring(0, lastDashIndex), deeplLocales)
        : null;
};

export const mapLocale = (locale: Locale, deeplLocales: Locale[] | undefined) => {
    if (!deeplLocales) {
        return null;
    }

    const localeUpperCase: Locale = locale.toUpperCase();

    if (deeplLocales.includes(localeUpperCase)) {
        return localeUpperCase;
    }

    return (
        findLowerSpecificityLocale(localeUpperCase, deeplLocales) ??
        findHigherSpecificityLocale(localeUpperCase, deeplLocales) ??
        findSameSpecificityLocale(localeUpperCase, deeplLocales) ??
        null
    );
};
