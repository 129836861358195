import { useEffect } from "react";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { Flex } from "@contentful/f36-components";
import { useSDK } from "@contentful/react-apps-toolkit";
import { DeeplUsageWidget, TranslationCard } from "../components";
import { UpdateIndicator } from "../components/UpdateIndicator";
import { useTranslationContext } from "../context";
import { useFieldChangeHandler } from "../hooks/useFieldChangeHandler";
import { isUpdateNotificationEnabled } from "../lib/flags";

export const Sidebar = () => {
    const sdk = useSDK<SidebarAppSDK>();
    const { state } = useTranslationContext();
    const { fieldChangeHandler } = useFieldChangeHandler();
    const { translationRequests } = state;

    useEffect(() => {
        const detachers = fieldChangeHandler();

        return () => detachers.forEach(detach => detach());
    }, [fieldChangeHandler]);

    useEffect(() => {
        sdk.window.startAutoResizer();

        return sdk.window.stopAutoResizer;
    }, [sdk]);

    return (
        <Flex flexDirection="column" gap="1rem" alignItems="flex-start">
            {translationRequests.length > 0 && <TranslationCard />}
            <DeeplUsageWidget />
            {isUpdateNotificationEnabled && <UpdateIndicator shouldHideAfterView />}
        </Flex>
    );
};
