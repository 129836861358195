/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import { ConfigAppSDK } from "@contentful/app-sdk";
import {
    Box,
    Flex,
    FormControl,
    Heading,
    Paragraph,
    Text,
    TextInput,
    TextLink,
} from "@contentful/f36-components";
import { useSDK } from "@contentful/react-apps-toolkit";
import { DeeplUsageWidget, FormalSpeechSelect } from "../components";
import { SupportedLocales } from "../components/SupportedLocales";
import { UpdateIndicator } from "../components/UpdateIndicator";
import { AppInstallationParameters, Formality } from "../lib";
import { isUpdateNotificationEnabled } from "../lib/flags";
import { useFetchDeeplUsage } from "../services";
import { styles } from "./styles";

export const ConfigScreen = () => {
    const [parameters, setParameters] = useState<AppInstallationParameters>({
        deeplApiKey: "",
        formalSpeech: "default",
        masked: false,
    });

    const sdk = useSDK<ConfigAppSDK>();
    const { data: usage } = useFetchDeeplUsage();

    const maskHandler = useCallback((toMask: string, masked: boolean): string => {
        if (masked) {
            return toMask.replace(/.{10}$/, "**********");
        }

        return toMask;
    }, []);

    const onConfigure = useCallback(async () => {
        const currentState = await sdk.app.getCurrentState();

        return {
            parameters: {
                ...parameters,
                masked: true,
            },

            targetState: currentState,
        };
    }, [parameters, sdk]);

    useEffect(() => {
        sdk.app.onConfigure(() => onConfigure());
    }, [sdk, onConfigure]);

    useEffect(() => {
        (async () => {
            const currentParameters: AppInstallationParameters | null =
                await sdk.app.getParameters();

            if (currentParameters) {
                setParameters(currentParameters);
            }

            sdk.app.setReady();
        })();
    }, [sdk]);

    return (
        <>
            <div css={styles.background} />
            <div css={styles.body}>
                <Heading css={styles.spaced}>About Linguiny</Heading>
                <Paragraph>
                    This app allows you to translate your content to different languages with the
                    help of DeepL (Google Translate coming soon).
                </Paragraph>
                <hr css={styles.splitter} />
                <Heading css={styles.spaced}>Configuration</Heading>
                <FormControl>
                    <FormControl.Label isRequired>DeepL API Key</FormControl.Label>
                    <TextInput
                        value={maskHandler(parameters.deeplApiKey, parameters.masked)}
                        onChange={e =>
                            setParameters({
                                ...parameters,
                                deeplApiKey: e.target.value,
                                masked: false,
                            })
                        }
                    />
                    <Box marginTop="spacingM">
                        {usage?.character_count !== 0 ? (
                            <DeeplUsageWidget />
                        ) : (
                            <Text>
                                You find your API Key on your{" "}
                                <TextLink
                                    href="https://www.deepl.com/de/account/summary"
                                    target="_blank">
                                    DeepL Account Summary
                                </TextLink>
                                .
                            </Text>
                        )}
                    </Box>
                </FormControl>
                <FormalSpeechSelect
                    value={parameters.formalSpeech}
                    onChange={e =>
                        setParameters({
                            ...parameters,
                            formalSpeech: e.target.value as Formality,
                        })
                    }
                />
                <hr css={styles.splitter} />
                <SupportedLocales />
                {isUpdateNotificationEnabled && (
                    <>
                        <hr css={styles.splitter} />
                        <UpdateIndicator />
                    </>
                )}
            </div>
            <Flex css={styles.logoContainer}>
                <Text css={styles.logo}>
                    Built with ❤️ by <TextLink href="https://www.becklyn.com">Becklyn</TextLink>.
                </Text>
            </Flex>
        </>
    );
};
