import { useMemo } from "react";
import { useSDK } from "@contentful/react-apps-toolkit";
import { Sidebar } from ".";
import { Loading } from "../components";
import { TranslationContextProvider } from "../context";
import { defaultState } from "../context/types";
import { useFetchDeeplLocales } from "../services";
import { useFetchDeeplUsage } from "../services/useFetchDeeplUsage";

export const SidebarWrapper = () => {
    const sdk = useSDK();

    const contentfulLocales = useMemo(() => {
        return Object.keys(sdk.locales.names)
            .map(key => ({
                locale: key,
                label: sdk.locales.names[key],
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [sdk.locales.names]);

    const {
        deeplSourceLocales,
        deeplTargetLocales,
        isDeeplSourceLocalesLoading,
        isDeeplTargetLocalesLoading,
    } = useFetchDeeplLocales();

    const { isLoading: isUsageLoading, data: usage, error: usageError } = useFetchDeeplUsage();

    if (isDeeplSourceLocalesLoading || isDeeplTargetLocalesLoading || isUsageLoading) {
        return <Loading />;
    }

    if (!usage || usageError) {
        sdk.notifier.error("Error fetching Usage");
        return null;
    }

    if (!deeplSourceLocales || !deeplTargetLocales) {
        sdk.notifier.error("Error fetching DeepL locales");
        return null;
    }

    const initialState = {
        ...defaultState,
        contentfulLocales,
        deeplSourceLocales,
        deeplTargetLocales,
        usage,
        formality: sdk.parameters.installation.formalSpeech ?? "default",
    };

    return (
        <TranslationContextProvider initialState={initialState}>
            <Sidebar />
        </TranslationContextProvider>
    );
};
