export const formalSpeechOptions = [
    { key: "default", label: "Default" },
    { key: "prefer_more", label: "Prefer more" },
    { key: "prefer_less", label: "Prefer less" },
];

export const preferredHigherSpecificityLocales: { [key: string]: string } = {
    EN: "EN-US",
    ZH: "ZH-HANS",
};

export const preferredSameSpecificityLocales: { [key: string]: string } = {
    "EN-EU": "EN-GB",
};

/** These are languages that are supported in Deepl for translation,
 * but are not yet being returned from the outdated API endpoint /languages
 * (as of 23.01.2025) */
export const missingSourceLanguages = ["AR"];
export const missingTargetLanguages = ["AR", "ZH-HANT"];
